import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'
import routes from './routes'
import { isSSR } from '../utils'
import NotFound from '@pages/NotFound'

const getRouter = (store) => {
  const history = (isSSR() ? createMemoryHistory : createWebHistory)('/')

  const localeForHostname = () => /onf\.ca/.test(store.getters['Global/hostname']) ? 'fr' : 'en'

  routes.push(
    {
      path: '/',
      redirect: r => (routes.find(r => r.name === `home.${localeForHostname()}`)).path
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
      props: { code: '404' }
    }
  )

  const router = createRouter({
    history,
    linkActiveClass: 'active',
    routes,
    scrollBehavior () {
      const scrollableContainer = store.getters['Global/mainScrollableContainer']
      if (scrollableContainer) scrollableContainer.scrollTo(0, 0)
    }
  })

  router.beforeEach((to, from, next) => {
    const hostname = store.getters['Global/hostname']
    if (/onf\.ca/.test(hostname)) {
      // Force FR if onf.ca domain
      store.dispatch('Global/updateLocale', 'fr')
    } else if (to.meta.locale !== from.meta.locale) {
      // Update locale from path
      store.dispatch('Global/updateLocale', to.meta.locale)
    }

    if (store.getters['Global/shouldCloseLeftNav']) {
      store.dispatch('Global/updateLeftNavOpened', false)
    }

    next()
  })

  return router
}

export default getRouter
