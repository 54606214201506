const generateApiUrl = (hostname) => {
  // Always use dev api if localhost
  if (hostname.match(/(localhost)/)) {
    return 'https://publicapi.dev.nfb.ca'
  }

  // Use suffix of current envrionment otherwise
  // Will be in the second captured group (index: 2)
  const suffixMatches = hostname.match(/(collection)(\..+)(\.((nfb|onf)\.ca))/)
  return `https://publicapi${suffixMatches?.length ? suffixMatches[2] : ''}.nfb.ca`
}

const apiCategoryEndpoints = {
  film: '/api/v5/films',
  interactive: '/api/v5/interactive',
  series: '/api/v5/series',
  collection: '/api/v5/collections'
}

const apiWorksEndpoint = '/api/v5/works'

const apiBlogFeed = {
  en: 'https://blog.nfb.ca/blog/category/curators-perspective/feed/',
  fr: 'https://blogue.onf.ca/blogue/category/perspective-du-conservateur/feed/'
}

const blogUrl = {
  en: 'https://blog.nfb.ca/blog/category/curators-perspective/',
  fr: 'https://blogue.onf.ca/blogue/category/perspective-du-conservateur/'
}

module.exports = {
  generateApiUrl,
  apiWorksEndpoint,
  apiCategoryEndpoints,
  apiBlogFeed,
  blogUrl
}
