import i18next from 'i18next'
import ICU from 'i18next-icu'
import enTranslations from '@i18n/en_CA/app.json'
import frTranslations from '@i18n/fr_CA/app.json'
import enErrorTranslations from '@i18n/en_CA/errors-messages.json'
import frErrorTranslations from '@i18n/fr_CA/errors-messages.json'

i18next
  .use(ICU)
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    resources: {
      en: {
        translation: enTranslations,
        errors: enErrorTranslations
      },
      fr: {
        translation: frTranslations,
        errors: frErrorTranslations
      }
    }
  })

export default i18next
