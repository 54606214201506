import i18nRoutes from './i18nRoutes'

const routes = []

for (const name in i18nRoutes) {
  const { path, component, props } = i18nRoutes[name]

  routes.push({
    path: path.en,
    name: `${name}.en`,
    component: () => import(`@pages/${component}.vue`),
    meta: {
      rawName: name,
      locale: 'en'
    },
    props
  },
  {
    path: path.fr,
    name: `${name}.fr`,
    component: () => import(`@pages/${component}.vue`),
    meta: {
      rawName: name,
      locale: 'fr'
    },
    props
  })
}

export default routes
