<template>
  <LayoutLeftNav
    @change="onNavChange"
    :locale="locale"
    :home-link="homeLink"
    ref="leftNav"
    skip-to-content-id="l-mainPageContent"
  >
    <ul class="main-nav">
      <li>
        <router-link
          class="search-link"
          :to="{ name: `search.${locale}` }"
          data-gtm-event="nfb_menu"
          data-gtm-nfb_detail="sidebar"
        >
          <span v-html="$t('nav.search')" />
          <i class="icon-loupe" />
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: `about.${locale}` }"
          data-gtm-event="nfb_menu"
          data-gtm-nfb_detail="sidebar"
        >
          <span v-html="$t('nav.about')" />
        </router-link>
      </li>
    </ul>
    <ul id="socialNav">
      <li
        v-for="item in socialItems"
        :key="item.icon"
      >
        <a
          :href="item.url"
          rel="external"
          target="_blank"
          :title="item.title"
          data-gtm-event="nfb_menu"
          data-gtm-nfb_detail="sidebar"
        >
          <i
            :class="['icon', `icon-${item.icon}`]"
            :title="item.title"
            :aria-label="item.title"
          />
        </a>
      </li>
    </ul>
    <ul id="languageSwitch">
      <li>
        <LocaleSwitcher />
      </li>
    </ul>
  </LayoutLeftNav>
</template>

<script>
import { mapGetters } from 'vuex'
import { LayoutLeftNav } from '@web-nfb/vue-3-components'
import LocaleSwitcher from '@components/LocaleSwitcher'
import enTranslations from '@i18n/en_CA/app.json'
import frTranslations from '@i18n/fr_CA/app.json'

export default {
  name: 'LeftNav',
  components: {
    LayoutLeftNav,
    LocaleSwitcher
  },
  computed: {
    ...mapGetters({
      locale: 'Global/locale',
      shouldCloseLeftNav: 'Global/shouldCloseLeftNav'
    }),
    homeLink () {
      return {
        tag: 'router-link',
        props: {
          to: {
            name: `home.${this.locale}`
          }
        }
      }
    },
    i18n () {
      return {
        en: enTranslations.nav.header,
        fr: frTranslations.nav.header
      }
    },
    socialItems () {
      return [
        {
          icon: 'facebook',
          url: this.$t('url.facebook'),
          title: 'Facebook'
        },
        {
          icon: 'twitter-x',
          url: this.$t('url.twitter'),
          title: 'Twitter'
        },
        {
          icon: 'instagram',
          url: this.$t('url.instagram'),
          title: 'Instagram'
        },
        {
          icon: 'youtube',
          url: this.$t('url.youtube'),
          title: 'Youtube'
        }
      ]
    }
  },
  watch: {
    shouldCloseLeftNav: {
      handler () {
        this.toggleCollapse()
      }
    },
    $route: {
      handler () {
        if (this.shouldCloseLeftNav) {
          this.$refs.leftNav?.close()
        }
      }
    }
  },
  mounted () {
    this.toggleCollapse()
  },
  methods: {
    onNavChange (isOpened) {
      this.$store.dispatch('Global/updateLeftNavActivated', true)
      this.$store.dispatch('Global/updateLeftNavOpened', isOpened)
    },
    toggleCollapse () {
      if (this.shouldCloseLeftNav) {
        this.$refs.leftNav?.forceCollapse()
      } else {
        this.$refs.leftNav?.unforceCollapse()
      }
    }
  }
}
</script>

<style lang="less">
@import (reference) "~@less/shared/utilities.less";
#l-leftNav {
  --nfb-accent-color: @nfb-green;

  .search-link {
    .flex(@justify: space-between);
    margin-bottom: 48px;
    max-width: none;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main-nav {
      flex-grow: 1;
    }
  }
}
</style>
