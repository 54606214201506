import { buildApp } from './app'

const { app, router, store } = buildApp(window.ENV_VAR)

const storeInitialState = window.INITIAL_DATA
if (storeInitialState) {
  store.replaceState(storeInitialState)
}

router.isReady().then(() => {
  app.mount('#app', true)
})
