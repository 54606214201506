const i18nRoutes = {
  home: {
    path: {
      en: '/home',
      fr: '/accueil'
    },
    component: 'Home'
  },
  search: {
    path: {
      en: '/search',
      fr: '/recherche'
    },
    component: 'Search'
  },
  about: {
    path: {
      en: '/about',
      fr: '/a-propos'
    },
    component: 'About'
  },
  film: {
    path: {
      en: '/film/:slug',
      fr: '/film/:slug'
    },
    component: 'FilmItem'
  },
  filmIdFallback: {
    // will become legacy
    path: {
      en: '/film/id/:id',
      fr: '/film/id/:id'
    },
    component: 'FilmItem'
  },
  interactive: {
    path: {
      en: '/interactive/:slug',
      fr: '/interactif/:slug'
    },
    component: 'InteractiveItem'
  },
  interactiveIdFallback: {
    // will become legacy
    path: {
      en: '/interactive/id/:id',
      fr: '/interactif/id/:id'
    },
    component: 'InteractiveItem'
  },
  series: {
    path: {
      en: '/series/:slug',
      fr: '/series/:slug'
    },
    component: 'SeriesItem'
  },
  seriesIdFallback: {
    // will become legacy
    path: {
      en: '/series/id/:id',
      fr: '/series/id/:id'
    },
    component: 'SeriesItem'
  },
  collection: {
    path: {
      en: '/collection/:slug',
      fr: '/collection/:slug'
    },
    component: 'CollectionItem'
  },
  collectionIdFallback: {
    // will become legacy
    path: {
      en: '/collection/id/:id',
      fr: '/collection/id/:id'
    },
    component: 'CollectionItem'
  },
  error: {
    path: {
      en: '/error/:code',
      fr: '/erreur/:code'
    },
    component: 'NotFound',
    props: true
  }
}

module.exports = i18nRoutes
