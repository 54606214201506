import axios from 'axios'
import find from 'lodash/find'
import {
  apiCategoryEndpoints,
  apiWorksEndpoint
} from '../helpers/urls'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      error: false,
      worksLoaded: {
        en: {
          film: [],
          series: [],
          interactive: [],
          collection: []
        },
        fr: {
          film: [],
          series: [],
          interactive: [],
          collection: []
        }
      }
    }
  },
  mutations: {
    SET_LOADING (state, val) {
      state.loading = val
    },
    SET_ERROR (state, val) {
      state.error = val
    },
    SET_WORK_LOADED (state, { locale, type, val }) {
      state.worksLoaded[locale][type] = [...state.worksLoaded[locale][type], val]
    }
  },
  actions: {
    async loadByTypeAndSlugOrId ({ commit, rootState, getters }, { type, slug, id }) {
      if (getters.getItemByTypeAndSlugOrId({ type, slug, id })) {
        return
      }

      commit('SET_LOADING', true)
      commit('SET_ERROR', false)

      let url = apiCategoryEndpoints[type]

      if (id) {
        url = `${url}/id/${id}`
      } else {
        url = `${url}/${slug}`
      }

      try {
        const { data: workResults } = await axios({
          method: 'get',
          baseURL: rootState.Global.baseApiUrl,
          url,
          params: {
            locale: rootState.Global.locale,
            description_type: 'catalog'
          }
        })

        const work = {
          locale: rootState.Global.locale,
          type,
          val: {
            id: workResults.item.id,
            slug: workResults.item.slug,
            item: workResults.item,
            meta: workResults.meta
          }
        }

        // Is film part of a serie?
        if (type === 'film') {
          const { data: seriesResults } = await axios({
            method: 'get',
            baseURL: rootState.Global.baseApiUrl,
            url: apiCategoryEndpoints.series,
            params: {
              locale: rootState.Global.locale,
              film_id: workResults.item.id,
              description_type: 'catalog'
            }
          }).catch((e) => e)

          if (seriesResults?.items?.length) {
            work.val.item.parent_series = seriesResults.items
          }
        }

        // Search for opposite language work
        if (workResults.item.expression_id) {
          const { data: oppositeResults } = await axios({
            method: 'get',
            baseURL: rootState.Global.baseApiUrl,
            url: apiWorksEndpoint,
            params: {
              expression_id: workResults.item.expression_id,
              locale: rootState.Global.locale,
              description_type: 'catalog'
            }
          }).catch((e) => e)
          if (oppositeResults?.items) {
            work.val.item.opposite_work_list = oppositeResults?.items.filter(item => item.id !== workResults.item.id)
          }
        }

        commit('SET_WORK_LOADED', work)
      } catch (error) {
        commit('SET_ERROR', true)
      }
      commit('SET_LOADING', false)
    }
  },
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    getItemByTypeAndSlugOrId: (state, getters, rootState) => ({ type, slug, id }) => {
      if (!state.worksLoaded[rootState.Global.locale][type]) {
        return false
      }

      if (slug) {
        return find(state.worksLoaded[rootState.Global.locale][type], { slug })?.item
      }

      return find(state.worksLoaded[rootState.Global.locale][type], { id: parseInt(id) })?.item
    },
    getMetaByTypeAndSlugOrId: (state, getters, rootState) => ({ type, slug, id }) => {
      if (!state.worksLoaded[rootState.Global.locale][type]) {
        return false
      }

      if (slug) {
        return find(state.worksLoaded[rootState.Global.locale][type], { slug })?.meta
      }

      return find(state.worksLoaded[rootState.Global.locale][type], { id: parseInt(id) })?.meta
    }
  }
}
