export default [
  {
    label: 'category.film',
    value: 'film'
  },
  {
    label: 'category.series',
    value: 'series,collection'
  },
  {
    label: 'category.interactive',
    value: 'interactive'
  }
]
