import axios from 'axios'
import qs from 'qs'
import { apiWorksEndpoint, apiCategoryEndpoints } from '../helpers/urls'
import { defaultDuration, defaultProductionYear } from './FilterBar'
import productionModelType from '@data/filter-production-model'

export default {
  namespaced: true,
  state () {
    return {
      searched: false,
      loading: false,
      error: false,
      query: '',
      items: [],
      thumbPattern: {
        films: '',
        series: '',
        interactive: ''
      },
      currentPage: 1,
      nbPages: 0,
      total: 0,
      nbPerPage: 12
    }
  },
  mutations: {
    SET_SEARCHED (state, val) {
      state.searched = val
    },
    SET_LOADING (state, val) {
      state.loading = val
    },
    SET_ERROR (state, val) {
      state.error = val
    },
    SET_QUERY (state, val) {
      state.query = val
    },
    SET_ITEMS (state, val) {
      state.items = val
    },
    SET_THUMB_PATTERN (state, val) {
      state.thumbPattern = val
    },
    SET_CURRENT_PAGE (state, val) {
      state.currentPage = val
    },
    SET_NB_PAGES (state, val) {
      state.nbPages = val
    },
    SET_TOTAL (state, val) {
      state.total = val
    },
    SET_NB_PER_PAGE (state, val) {
      state.nbPerPage = val
    }
  },
  actions: {
    setQuery ({ commit }, val) {
      return commit('SET_QUERY', val)
    },
    setCurrentPage ({ commit }, val) {
      return commit('SET_CURRENT_PAGE', val)
    },
    reset ({ commit }) {
      commit('SET_QUERY', '')
      commit('SET_SEARCHED', false)
      commit('SET_ERROR', false)
      commit('SET_LOADING', false)
      commit('SET_ITEMS', [])
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_NB_PAGES', 0)
    },
    goToPage ({ commit, dispatch }, val) {
      commit('SET_CURRENT_PAGE', val)
      dispatch('search')
    },
    search ({ commit, state, rootState }, { backToPageOne = false } = {}) {
      if (state.query) {
        commit('SET_LOADING', true)
        commit('SET_ERROR', false)

        const {
          duration, genre, language, interactiveFormat,
          orderBy, productionYear, type, excludeAcquisitions, sortBy
        } = rootState.FilterBar
        const isInteractive = type === 'interactive'

        const params = {
          collection: 'gc/gc-films',
          query: state.query,
          page: backToPageOne ? 1 : state.currentPage,
          size: state.nbPerPage,
          locale: rootState.Global.locale,
          description_type: 'catalog',
          ...(duration.min !== defaultDuration.min && { duration_min: duration.min * 60 }),
          ...(duration.max !== defaultDuration.max && { duration_max: duration.max * 60 }),
          ...(productionYear.max !== defaultProductionYear.max && { year_max: productionYear.max }),
          ...(productionYear.min !== defaultProductionYear.min && { year_min: productionYear.min }),
          ...(language !== 'all' && { language }),
          ...(sortBy !== 'relevance' && { sort: `${sortBy}:${orderBy}` }),
          ...(genre && (type === 'film' || type === 'series') && { genre }),
          ...(isInteractive && interactiveFormat && { expression_type: interactiveFormat }),
          ...(type && { category: type }),
          ...(excludeAcquisitions && { production_models: [productionModelType.ONF, productionModelType.COPRODUCTION] })
        }

        const url = isInteractive ? apiCategoryEndpoints.interactive : apiWorksEndpoint

        return axios({
          method: 'get',
          baseURL: rootState.Global.baseApiUrl,
          url,
          params,
          paramsSerializer: params =>
            qs.stringify(params, { arrayFormat: 'repeat' })
        })
          .then(({ data }) => {
            commit('SET_ITEMS', data.items)
            commit('SET_THUMB_PATTERN', isInteractive ? { ...state.thumbPattern, interactive: data.meta.media.thumbnail_url } : data.meta.mixed_media?.thumbnail_url)
            commit('SET_NB_PAGES', data.meta.pagination.pages)
            commit('SET_CURRENT_PAGE', data.meta.pagination.page)
            commit('SET_TOTAL', data.meta.pagination.total)
            commit('SET_NB_PER_PAGE', data.meta.pagination.size)
          })
          .catch((e) => {
            commit('SET_ERROR', true)
          })
          .finally(() => {
            commit('SET_SEARCHED', true)
            commit('SET_LOADING', false)
          })
      }
    }
  },
  getters: {
    searched: state => state.searched,
    loading: state => state.loading,
    error: state => state.error,
    query: state => state.query,
    items: state => state.items,
    thumbPattern: state => state.thumbPattern,
    thumbPatternByCategory: state => cat => state.thumbPattern[cat],
    currentPage: state => state.currentPage,
    nbPages: state => state.nbPages,
    total: state => state.total,
    nbPerPage: state => state.nbPerPage,
    noResults: state => state.total < 1,
    pageHasChanged: state => state.currentPage > 1,
    buildQuery: (state, getters, rootState, rootGetters) => {
      return {
        ...(rootGetters['FilterBar/buildQueryFromFilters']),
        ...(state.query && { q: state.query }),
        ...(state.query && state.currentPage && getters.pageHasChanged && { page: state.currentPage })
      }
    }
  }
}
