import { createSSRApp, createApp } from 'vue'
import { createHead } from '@vueuse/head'
import I18NextVue from 'i18next-vue'
import VueGtm from '@gtm-support/vue-gtm'

import App from './App.vue'
import i18next from './i18n'
import { isSSR } from './utils'
import '@less/index.less'
import '@sass/index.sass'

import getRouter from './router'
import getStore from './store'

export const buildApp = (envVar = null) => {
  const store = getStore()
  const router = getRouter(store)
  const app = (isSSR() ? createSSRApp : createApp)(App)

  if (envVar && envVar.gtmId) {
    if (!isSSR()) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(envVar.gtmDataLayer)
    }
    app.use(VueGtm, {
      id: envVar.gtmId,
      debug: envVar.gtmDebug === 'true',
      loadScript: true,
      vueRouter: router
    })
  }

  const head = createHead()
  app.use(router)
  app.use(store)
  app.use(head)
  app.use(I18NextVue, { i18next })

  return { app, head, store, router }
}
