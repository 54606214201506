import axios from 'axios'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import find from 'lodash/find'
import get from 'lodash/get'

import { apiCategoryEndpoints } from '../helpers/urls'
import genreList from '@data/filter-genre-list'
import typeList from '@data/filter-type-list'
import formatList from '@data/filter-format-list'

export const defaultSortBy = 'relevance'
export const defaultOrderBy = 'desc'
export const defaultDuration = { min: 0, max: 250 }
export const defaultProductionYear = { min: 1914, max: new Date().getFullYear() }
export const defaultGenre = null
export const defaultType = null
export const defaultInteractiveFormat = null

export default {
  namespaced: true,
  state () {
    return {
      language: null,
      sortBy: defaultSortBy,
      orderBy: defaultOrderBy,
      type: defaultType,
      genre: defaultGenre,
      interactiveFormat: defaultInteractiveFormat,
      duration: defaultDuration,
      productionYear: defaultProductionYear,
      excludeAcquisitions: true,
      typeList: [],
      genreList: [],
      interactiveFormatList: { en: [], fr: [] }
    }
  },
  mutations: {
    SET_LANGUAGE (state, val) {
      state.language = val
    },
    SET_TYPE (state, val) {
      state.type = val
    },
    SET_GENRE (state, val) {
      state.genre = val
    },
    SET_INTERACTIVE_FORMAT (state, val) {
      state.interactiveFormat = val
    },
    SET_DURATION (state, val) {
      state.duration = val
    },
    SET_PRODUCTION_YEAR (state, val) {
      state.productionYear = val
    },
    SET_EXCLUDE_ACQUISITIONS (state, val) {
      state.excludeAcquisitions = val
    },
    SET_SORT_BY (state, val) {
      state.sortBy = val
    },
    SET_ORDER_BY (state, val) {
      state.orderBy = val
    },
    SET_TYPE_LIST (state, val) {
      state.typeList = val
    },
    SET_GENRE_LIST (state, val) {
      state.genreList = val
    },
    SET_INTERACTIVE_FORMAT_LIST (state, val) {
      state.interactiveFormatList = val
    }
  },
  actions: {
    initFiltersFromQuery ({ commit, rootState }, query) {
      commit('SET_LANGUAGE', query.language ?? rootState.Global.locale)
      commit('SET_TYPE', query.type ?? defaultType)
      commit('SET_GENRE', query.genre ?? defaultGenre)

      const [minDuration, maxDuration] = query.duration?.split(',') ?? [defaultDuration.min, defaultDuration.max]
      commit('SET_DURATION', { min: parseInt(minDuration), max: parseInt(maxDuration) })

      const [minYear, maxYear] = query.productionYear?.split(',') ?? [defaultProductionYear.min, defaultProductionYear.max]
      commit('SET_PRODUCTION_YEAR', { min: parseInt(minYear), max: parseInt(maxYear) })

      commit('SET_SORT_BY', query.sortBy ?? defaultSortBy)
      commit('SET_ORDER_BY', query.orderBy ?? defaultOrderBy)

      if (query.excludeAcquisitions === 'false') {
        commit('SET_EXCLUDE_ACQUISITIONS', false)
      }
    },
    setLanguageInitial ({ commit }, val) {
      commit('SET_LANGUAGE', val)
    },
    setLanguage ({ commit, dispatch }, val) {
      commit('SET_LANGUAGE', val)
      dispatch('newSearchToPageOne')
    },
    resetLanguage ({ commit, dispatch, rootState }, search = true) {
      commit('SET_LANGUAGE', rootState.Global.locale)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    setType ({ commit, dispatch }, val) {
      commit('SET_TYPE', val)
      dispatch('newSearchToPageOne')
    },
    resetType ({ commit, dispatch }, search = true) {
      commit('SET_TYPE', defaultType)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    setGenre ({ commit, dispatch }, val) {
      commit('SET_GENRE', val)
      dispatch('newSearchToPageOne')
    },
    resetGenre ({ commit, dispatch }, search = true) {
      commit('SET_GENRE', defaultGenre)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    setInteractiveFormat ({ commit, dispatch }, val) {
      commit('SET_INTERACTIVE_FORMAT', val)
      dispatch('newSearchToPageOne')
    },
    resetInteractiveFormat ({ commit, dispatch }, search = true) {
      commit('SET_INTERACTIVE_FORMAT', defaultInteractiveFormat)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    setDuration ({ commit, dispatch }, val) {
      commit('SET_DURATION', val)
      dispatch('newSearchToPageOne')
    },
    resetDuration ({ commit, dispatch }, search = true) {
      commit('SET_DURATION', defaultDuration)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    setProductionYear ({ commit, dispatch }, val) {
      commit('SET_PRODUCTION_YEAR', val)
      dispatch('newSearchToPageOne')
    },
    resetProductionYear ({ commit, dispatch }, search = true) {
      commit('SET_PRODUCTION_YEAR', defaultProductionYear)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    setExcludeAcquisitions ({ commit, dispatch }, val) {
      commit('SET_EXCLUDE_ACQUISITIONS', val)
      dispatch('newSearchToPageOne')
    },
    resetExcludeAcquisitions ({ commit, dispatch }, search = true) {
      commit('SET_EXCLUDE_ACQUISITIONS', true)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    setSort ({ commit, dispatch }, { sortBy, orderBy }) {
      commit('SET_SORT_BY', sortBy)
      commit('SET_ORDER_BY', orderBy)
      dispatch('newSearchToPageOne')
    },
    resetSort ({ commit, dispatch }, search = true) {
      commit('SET_SORT_BY', defaultSortBy)
      commit('SET_ORDER_BY', defaultOrderBy)
      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    reset ({ dispatch }, search = true) {
      dispatch('resetLanguage', false)
      dispatch('resetGenre', false)
      dispatch('resetDuration', false)
      dispatch('resetProductionYear', false)
      dispatch('resetExcludeAcquisitions', false)
      dispatch('resetSort', false)
      dispatch('resetType', false)

      if (search) {
        dispatch('newSearchToPageOne')
      }
    },
    loadTypeList ({ commit }) {
      // static file for now (vs API Call)
      commit('SET_TYPE_LIST', typeList)
    },
    loadGenreList ({ commit }) {
      // static file for now (vs API Call)
      commit('SET_GENRE_LIST', genreList)
    },
    async loadInteractiveFormatList ({ rootState, commit }) {
      let listWithI18n = {}

      return Promise.all(['en', 'fr'].map(locale => {
        return new Promise((resolve, reject) => {
          axios({
            method: 'get',
            baseURL: rootState.Global.baseApiUrl,
            url: apiCategoryEndpoints.interactive,
            params: { size: 0, locale }
          }).then(({ data }) => {
            if (data.meta?.types?.length) {
              listWithI18n[locale] = data.meta.types.map(type => ({ label: type, value: type }))
              resolve()
            } else {
              reject(new Error())
            }
          }).catch(e => { reject(e) })
        })
      })).catch((e) => {
        listWithI18n = {
          en: formatList.map(({ label, en_value: value }) => ({ value, label })),
          fr: formatList.map(({ label, fr_value: value }) => ({ value, label }))
        }
      }).finally(() => {
        commit('SET_INTERACTIVE_FORMAT_LIST', listWithI18n)
      })
    },
    newSearchToPageOne ({ dispatch }) {
      dispatch('Search/search', { backToPageOne: true }, { root: true })
    }
  },
  getters: {
    language: state => state.language,
    languageChanged: (state, getters, rootState) => state.language !== rootState.Global.locale,

    type: state => state.type,
    typeList: state => state.typeList,
    typeChanged: state => state.type !== defaultType,

    genre: state => state.genre,
    genreList: (state, getters, rootState) => map(state.genreList, data => ({ value: data[`slug_${rootState.Global.locale}`], label: data[`descr_${rootState.Global.locale}`] })),
    genreChanged: state => state.genre !== defaultGenre,
    oppositeGenre: state => ({ locale, slug }) => {
      if (locale === 'fr') {
        return get(find(state.genreList, { slug_fr: slug }), 'slug_en')
      }

      return get(find(state.genreList, { slug_en: slug }), 'slug_fr')
    },

    interactiveFormat: state => state.interactiveFormat,
    interactiveFormatList: (state, getters, rootState) => state.interactiveFormatList[rootState.Global.locale],

    duration: state => state.duration,
    durationChanged: state => !isEqual(state.duration, defaultDuration),

    selectListsAreLoaded: state => state.typeList.length && state.genreList.length && state.interactiveFormatList.en?.length && state.interactiveFormatList.fr?.length,

    productionYear: state => state.productionYear,
    productionYearChanged: state => !isEqual(state.productionYear, defaultProductionYear),

    excludeAcquisitions: state => state.excludeAcquisitions,
    excludeAcquisitionsChanged: state => state.excludeAcquisitions === false,

    sort: state => ({ sortBy: state.sortBy, orderBy: state.orderBy }),
    sortChanged: state => state.sortBy !== defaultSortBy || state.orderBy !== defaultOrderBy,

    showReset: (state, getters) => getters.languageChanged || getters.typeChanged || getters.genreChanged ||
      getters.durationChanged || getters.productionYearChanged || getters.excludeAcquisitionsChanged || getters.sortChanged,
    buildQueryFromFilters: (state, getters) => {
      return {
        ...(state.language && getters.languageChanged && { language: state.language }),
        ...(state.type && getters.typeChanged && { type: state.type }),
        ...(state.genre && getters.genreChanged && { genre: state.genre }),
        ...(state.duration && getters.durationChanged && { duration: `${state.duration.min},${state.duration.max}` }),
        ...(state.productionYear && getters.productionYearChanged && { productionYear: `${state.productionYear.min},${state.productionYear.max}` }),
        ...(getters.excludeAcquisitionsChanged && { excludeAcquisitions: state.excludeAcquisitions }),
        ...(state.sortBy && getters.sortChanged && { sortBy: state.sortBy }),
        ...(state.orderBy && getters.sortChanged && { orderBy: state.orderBy })
      }
    }
  }
}
