<template>
  <div class="newsletter-banner">
    <ContentContainer>
      <h4 class="h4 uppercase flex-container">
        <i class="icon icon-enveloppe-0" />
        <span v-html="$t('newsletter.title')" />
      </h4>
      <div class="flex-container">
        <p
          class="desc no-hyphens"
          v-html="$t('newsletter.description')"
        />
        <a
          :href="$t('newsletter.href')"
          target="_blank"
          rel="noopener"
          class="btn btn-primary plein"
          v-html="$t('newsletter.button')"
        />
      </div>
    </ContentContainer>
  </div>
</template>

<script>
import { LayoutContentContainer as ContentContainer } from '@web-nfb/vue-3-components'

export default {
  name: 'NewsletterBanner',
  components: {
    ContentContainer
  }
}
</script>

<style lang="less" scoped>
@import (reference) "~@less/shared/utilities.less";

.newsletter-banner {
  background: @grey-20;
  padding: @box-padding * 2 0;

  h4 {
    margin: 0;
    font-family: @font-family-semi-bold;

    i {
      margin-right: .5em;
    }
  }

  .desc {
    max-width: 340px;
    margin-right: 1em;
  }
}
</style>
