export default [
  {
    id: 59,
    descr_en: 'Fiction',
    descr_fr: 'Fiction',
    slug_en: 'fiction',
    slug_fr: 'fiction'
  },
  {
    id: 60,
    descr_en: 'Feature-length fiction',
    descr_fr: 'Long métrage de fiction',
    slug_en: 'feature-length-fiction',
    slug_fr: 'long-metrage-de-fiction'
  },
  {
    id: 61,
    descr_en: 'Animation',
    descr_fr: 'Animation',
    slug_en: 'animation',
    slug_fr: 'animation'
  },
  {
    id: 62,
    descr_en: 'Experimental',
    descr_fr: 'Expérimental',
    slug_en: 'experimental',
    slug_fr: 'experimental'
  },
  {
    id: 63,
    descr_en: "Children's film",
    descr_fr: 'Film pour enfants',
    slug_en: 'childrens-film',
    slug_fr: 'film-pour-enfants'
  },
  {
    id: 64,
    descr_en: 'News Magazine (1940-1965)',
    descr_fr: 'Actualité (1940-1965)',
    slug_en: 'news-magazine-1940-1965',
    slug_fr: 'actualite-1940-1965'
  },
  {
    id: 30500,
    descr_en: 'Documentary',
    descr_fr: 'Documentaire',
    slug_en: 'documentary',
    slug_fr: 'documentaire'
  }
]
