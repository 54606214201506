export const defaultLayout = 'detailed'

export default {
  namespaced: true,
  state () {
    return {
      currentLayout: defaultLayout,
      filtersOpened: false
    }
  },
  mutations: {
    SET_CURRENT_LAYOUT (state, val) {
      state.currentLayout = val
    },
    SET_FILTERS_OPENED (state, val) {
      state.filtersOpened = val
    }
  },
  actions: {
    setCurrentLayout ({ commit }, val) {
      commit('SET_CURRENT_LAYOUT', val)
    },
    toggleFiltersOpened ({ commit, state }) {
      commit('SET_FILTERS_OPENED', !state.filtersOpened)
    }
  },
  getters: {
    currentLayout: state => state.currentLayout,
    filtersOpened: state => state.filtersOpened
  }
}
