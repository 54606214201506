<script setup>
import { useHead } from '@vueuse/head'
import { useTranslation } from 'i18next-vue'
import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'

import { NotFound } from '@web-nfb/vue-3-components'
import { cleanUpString } from '@helpers/sanitize'

const store = useStore()
const { t } = useTranslation(['errors'])

const props = defineProps({
  code: {
    type: String,
    required: true
  }
})

useHead({
  title: computed(() =>
    cleanUpString(t(`errors.${props.code}.title`, { ns: 'errors' }))
  )
})

const locale = computed(() => store.getters['Global/locale'])

</script>

<template>
  <div
    data-cy="page-not-found"
  >
    <NotFound
      :locale="locale"
      :error="code"
    />
  </div>
</template>
