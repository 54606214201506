<template>
  <div class="locale-switcher">
    <a
      :href="href"
      data-cy="locale-switcher"
      v-on="!href ? { click: switchLocale } : {}"
    >
      <span v-html="$t('nav.oppositeLocale')" />
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LocaleSwitcher',
  computed: {
    ...mapGetters({
      locale: 'Global/locale',
      hostname: 'Global/hostname',
      oppositeGenre: 'FilterBar/oppositeGenre'
    }),
    oppositeLocale () {
      if (this.locale === 'en') {
        return 'fr'
      }

      return 'en'
    },
    oppositeDomain () {
      if (this.locale === 'en') {
        return this.hostname.replace('nfb.ca', 'onf.ca')
      }
      return this.hostname.replace('onf.ca', 'nfb.ca')
    },
    oppositeRoute () {
      let route = null
      const currentRouteName = this.$route.meta.rawName
      const oppositeRouteName = `${currentRouteName}.${this.oppositeLocale}`

      if (this.$router.hasRoute(oppositeRouteName)) {
        const query = { ...this.$route.query }
        if (query.page) {
          delete query.page
        }
        if (query.genre) {
          query.genre = this.oppositeGenre({ locale: this.locale, slug: query.genre })
        }

        route = this.$router.resolve({
          name: oppositeRouteName,
          query
        })
      }

      return route
    },
    href () {
      if (!(this.hostname.match(/(.nfb.ca|.onf.ca)/))) {
        return
      }

      return `${this.oppositeDomain}${this.oppositeRoute?.href ?? this.$router.currentRoute.value.fullPath}`
    }
  },
  methods: {
    ...mapActions({
      updateLocale: 'Global/updateLocale'
    }),
    switchLocale () {
      const route = this.oppositeRoute
      if (route) {
        this.$router.push({
          ...this.$route,
          name: `${this.$route.meta.rawName}.${this.oppositeLocale}`
        })
      } else if (this.oppositeLocale) {
        this.updateLocale(this.oppositeLocale)
      }
    }
  }
}
</script>
<style scoped lang="sass">
a
  cursor: pointer
</style>
