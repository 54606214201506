import { generateApiUrl } from '../helpers/urls'
import { isSSR } from '../utils'

export default {
  namespaced: true,
  state () {
    return {
      hostname: isSSR() ? null : window.location.origin,
      baseApiUrl: isSSR() ? null : generateApiUrl(window.location.origin),
      locale: 'en', // default locale,
      leftNavOpened: isSSR() ? false : window.innerWidth > 990,
      leftNavActivated: true,
      viewport: {
        width: isSSR() ? null : window?.innerWidth,
        height: isSSR() ? null : window?.innerHeight
      },
      shouldUseServerPrefetch: isSSR()
    }
  },
  mutations: {
    SET_HOSTNAME (state, val) {
      state.hostname = val

      if (!state.baseApiUrl) {
        state.baseApiUrl = generateApiUrl(val)
      }
    },
    SET_BASE_API_URL (state, val) {
      state.baseApiUrl = val
    },
    SET_LOCALE (state, val) {
      state.locale = val
    },
    SET_LEFT_NAV_OPENED (state, val) {
      state.leftNavOpened = val
    },
    SET_LEFT_NAV_ACTIVATED (state, val) {
      state.leftNavActivated = val
    },
    SET_VIEWPORT (state, val) {
      state.viewport = val
    },
    SET_SHOULD_USE_SERVER_PREFETCH (state, val) {
      state.shouldUseServerPrefetch = val
    }
  },
  actions: {
    updateHostname ({ commit }, val) {
      commit('SET_HOSTNAME', val)
    },
    updateBaseApiUrl ({ commit }, val) {
      commit('SET_BASE_API_URL', val)
    },
    updateLocale ({ commit }, val) {
      commit('SET_LOCALE', val)
    },
    updateLeftNavOpened ({ commit }, val) {
      commit('SET_LEFT_NAV_OPENED', val)
    },
    updateLeftNavActivated ({ commit }, val) {
      commit('SET_LEFT_NAV_ACTIVATED', val)
    },
    updateViewport ({ commit }, val) {
      commit('SET_VIEWPORT', val)
    },
    updateViewportFromWindow ({ commit, dispatch, getters }, updateLeftNav = false) {
      commit('SET_VIEWPORT', {
        width: window.innerWidth,
        height: window.innerHeight
      })
      if (updateLeftNav) {
        dispatch('updateLeftNavOpened', !getters.shouldCloseLeftNav)
      }
    },
    updateShouldUseServerPrefetch ({ commit }, val) {
      commit('SET_SHOULD_USE_SERVER_PREFETCH', val)
    }
  },
  getters: {
    hostname: state => state.hostname,
    locale: state => state.locale,
    leftNavOpened: state => state.leftNavOpened,
    leftNavActivated: state => state.leftNavActivated,
    viewport: state => state.viewport,
    viewportWidth: state => state.viewport.width,
    isSmallMobile: state => state.viewport.width && state.viewport.width < 480,
    isMobile: state => state.viewport.width && state.viewport.width < 600,
    isTablet: state => state.viewport.width && state.viewport.width >= 600 && state.viewport.width < 1024,
    isDesktop: state => state.viewport.width && state.viewport.width >= 1024,
    shouldCloseLeftNav: state => state.viewport.width && state.viewport.width < 990,
    shouldUseServerPrefetch: state => state.shouldUseServerPrefetch,
    mainScrollableContainer: (state, getters) => getters.shouldCloseLeftNav ? window : document.getElementById('l-generalContainer')
  }
}
