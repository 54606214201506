export default [
  {
    label: 'interactiveFormats.360',
    en_value: '360 Video',
    fr_value: 'Vidéo 360'
  },
  {
    label: 'interactiveFormats.dome',
    en_value: 'Dome Projection',
    fr_value: 'Projection Dôme'
  },
  {
    label: 'interactiveFormats.installation',
    en_value: 'Installation',
    fr_value: 'Installation'
  },
  {
    label: 'interactiveFormats.socialMedia',
    en_value: 'Social Media',
    fr_value: 'Médias sociaux'
  },
  {
    label: 'interactiveFormats.vr',
    en_value: 'VR/AR/Mixed Reality',
    fr_value: 'VR/AR/Réalité mixte'
  },
  {
    label: 'interactiveFormats.web',
    en_value: 'Web Experience',
    fr_value: 'Expérience Web'
  },
  {
    label: 'interactiveFormats.application',
    en_value: 'Interactive application',
    fr_value: 'Application interactive'
  },
  {
    label: 'interactiveFormats.audio',
    en_value: 'Audio',
    fr_value: 'Audio'
  },
  {
    label: 'interactiveFormats.game',
    en_value: 'Game',
    fr_value: 'Jeu'
  }
]
