import axios from 'axios'
import { apiBlogFeed } from '../helpers/urls'
import { isSSR } from '../utils/index'

const DEFAULT_NB_BLOG_CARDS = 11

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      error: false,
      postList: []
    }
  },
  mutations: {
    SET_LOADING (state, val) {
      state.loading = val
    },
    SET_ERROR (state, val) {
      state.error = val
    },
    SET_POST_LIST (state, val) {
      state.postList = val
    }
  },
  actions: {
    loadPostList ({ rootState, commit }) {
      if (!isSSR()) {
        const parser = new DOMParser()
        return axios({
          method: 'get',
          url: apiBlogFeed[rootState.Global.locale]
        })
          .then(({ data }) => {
            const result = parser.parseFromString(data, 'text/xml')
            const items = result.getElementsByTagName('rss')[0].getElementsByTagName('channel')[0].getElementsByTagName('item')
            commit('SET_POST_LIST', [...items].map(data => ({
              title: data.getElementsByTagName('title')[0].textContent,
              link: data.getElementsByTagName('link')[0].textContent,
              thumbnail: data.getElementsByTagName('enclosure')[0].getAttribute('url'),
              creator: {
                name: data.getElementsByTagName('dc:creator')[0].textContent,
                img: data.getElementsByTagName('img')[0].getAttribute('src')
              }
            })))
          })
          .catch(() => {
            commit('SET_ERROR', true)
          })
          .finally(() => {
            commit('SET_ERROR', false)
            commit('SET_LOADING', false)
          })
      }
    }
  },
  getters: {
    loading: state => state.loading,
    postList: state => state.postList,
    getLastPosts: state => (nb = DEFAULT_NB_BLOG_CARDS) => nb < state.postList.length ? state.postList.slice(0, nb) : state.postList
  }
}
