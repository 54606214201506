<script setup>
import { useHead } from '@vueuse/head'
import { useTranslation } from 'i18next-vue'
import throttle from 'lodash/throttle'
import { computed, watch, onMounted, onUnmounted, onServerPrefetch } from 'vue'
import { useStore } from 'vuex'

import LeftNav from '@components/LeftNav'
import NewsletterBanner from '@components/NewsletterBanner'
import { LayoutFooter as Footer } from '@web-nfb/vue-3-components'
import { cleanUpString } from '@helpers/sanitize'

const store = useStore()
const { t, i18next } = useTranslation()

const metaDescription = computed(() => cleanUpString(t('meta.description')))
const metaImage = computed(() => t('meta.image'))

useHead({
  titleTemplate: computed(() => cleanUpString(t('meta.titleTemplate'))),
  meta: [
    {
      property: 'og:image',
      content: metaImage
    },
    {
      property: 'og:description',
      content: metaDescription
    },
    {
      name: 'twitter:card',
      content: metaDescription
    }
  ]
})

const locale = computed(() => store.getters['Global/locale'])
const leftNavOpened = computed(() => store.getters['Global/leftNavOpened'])
const leftNavActivated = computed(() => store.getters['Global/leftNavActivated'])
const selectListsAreLoaded = computed(() => store.getters['FilterBar/selectListsAreLoaded'])

const loadTypeList = () => store.dispatch('FilterBar/loadTypeList')
const loadGenreList = () => store.dispatch('FilterBar/loadGenreList')
const loadInteractiveFormatList = () => store.dispatch('FilterBar/loadInteractiveFormatList')
const updateViewportFromWindow = () => store.dispatch('Global/updateViewportFromWindow')
const updateLeftNavActivated = (value) => store.dispatch('Global/updateLeftNavActivated', value)
const setLanguageInitial = () => store.dispatch('FilterBar/setLanguageInitial', locale.value)

setLanguageInitial()

const updateLocale = () => {
  i18next.changeLanguage(locale.value)
}

const onResize = throttle(() => {
  store.dispatch('Global/updateViewportFromWindow')
  updateViewportFromWindow()
  updateLeftNavActivated(false)
}, 200)

const loadFiltersList = async () => {
  if (!selectListsAreLoaded.value) {
    await loadGenreList()
    await loadTypeList()
    await loadInteractiveFormatList()
  }
}

const showNewsletterBanner = () => {
  return ['film', 'series', 'seriesIdFallback', 'interactive', 'interactiveIdFallback'].indexOf(this.$route.meta.rawName) !== -1
}

watch(locale, () => updateLocale(), { immediate: true })

onMounted(() => {
  window.addEventListener('resize', onResize)
  store.dispatch('Global/updateViewportFromWindow', true)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

onServerPrefetch(async () => { await loadFiltersList() })

onMounted(() => {
  if (!selectListsAreLoaded.value) {
    loadFiltersList()
  }
})

</script>
<template>
  <div
    id="l-generalContainer"
    :class="[{'is-activated': leftNavActivated, 'is-nav-open': leftNavOpened }, 'is-nav-close']"
  >
    <LeftNav />
    <div id="l-rightContentSide">
      <main
        id="l-mainPageContent"
        class="is-loaded"
      >
        <router-view />
      </main>
      <div class="footers-container">
        <NewsletterBanner
          v-if="showNewsletterBanner"
          data-cy="newsletter-banner"
        />
        <Footer
          :locale="locale"
        />
      </div>
    </div>
  </div>
</template>
