import { createStore } from 'vuex'

import Global from './Global'
import Search from './Search'
import Work from './Work'
import FilterBar from './FilterBar'
import ResultOptions from './ResultOptions'
import Blog from './Blog'

const getStore = () => {
  return createStore({
    modules: {
      Global,
      Search,
      Work,
      FilterBar,
      ResultOptions,
      Blog
    }
  })
}

export default getStore
